import React, { useState, useEffect } from "react"
import GeneralHours from "@bit/azheng.joshua-tree.general-hours"
import { graphql } from "gatsby"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import Seamless from "../components/SeamlessEmbed"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import LocationBlocks from "./contact/LocationBlocks"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import { SocialIconLink } from "../components/SocialReviews"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import { getPostObj, telephoneStrip } from "../utils/utils"
import LocationForms from "./contact/LocationForms"

function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")

const converter = new showdown.Converter()

const ContactUsPage = ({ pageContext, location, data }) => {
  let post
  let language
  const postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  post = postObj.post
  language = postObj.language

  const { locationHours } = data.allLocationsJson.nodes[0].locationData
  const locations = data.allLocationsJson.nodes
  let socials = locations[0].locationData.socialAccounts
  const allowedSocials = ["Facebook", "Instagram"]
  socials = socials.filter(s => allowedSocials.includes(s.platform))
  const [activeForm, setActiveForm] = useState({ id: "", name: "" })
  const [pageState, setPageState] = useState("")

  useEffect(() => {
    if (typeof window === "undefined") return

    if (location.state?.formId) {
      setActiveForm({
        ...activeForm,
        id: location.state.formId,
        name: location.state.locationName
      })
    } else {
      setActiveForm({ id: "", name: "" })
    }
  }, [location.state?.formId])

  useEffect(() => {
    if (typeof window === "undefined") return

    if (pageState !== location.state?.key) {
      setPageState(location.state?.key)
    }
  }, [location.state?.key])

  const handleFormChange = (formId, locationName) => {
    if (formId === activeForm.id) {
      const form = document.getElementById("contact-page-form")
      setTimeout(() => {
        window.scroll({
          top: form.offsetTop - 100,
          left: 0,
          behavior: "smooth"
        })
      }, 600)
    } else {
      setActiveForm(prevState => ({
        id: formId,
        name: locationName
      }))
    }
  }

  return (
    <SharedStateProvider>
      <Layout
        className={`contact-us ${language === "es" ? "es" : null}`}
        layoutClass="contact-fab"
        language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
        />
        <span className="menu-spacer" />

        <div className="contact-top-wrapper">
          <div className="is-hidden-desktop is-hidden-tablet">
            <NuvoImage
              className="splash"
              cloudName="nuvolum"
              publicId="FLSDE/DEV/contact-page-splash-marker.jpg"
              useAR
              width="auto"
            />
          </div>
          <div className="is-hidden-mobile">
            <NuvoImage
              className="splash"
              cloudName="nuvolum"
              publicId={post.googleMapsBackground.splashPublicId}
              useAR
              width="auto"
            />
          </div>

          <div className="contact-top-headings">
            <h1>{post.heading}</h1>
          </div>
        </div>

        <div className="body-section contact-us-body">
          <div className="columns">
            <div className="column is-4" />
            <div
              className="column is-10"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}>
              <div className="contact-info is-hidden-tablet">
                {locations.length === 1 && (
                  <>
                    <div className="contact-sidebar-block">
                      <h7>{language === "es" ? "Dirección" : "Address"}</h7>
                      <a
                        href={`${locations[0].locationData.googlePlaceLink}`}
                        target="_blank"
                        rel="noreferrer">
                        {`${locations[0].locationData.streetNumber} ${locations[0].locationData.streetName}`}
                        <br />
                        {`${locations[0].locationData.cityName}, ${locations[0].locationData.stateAbbr} ${locations[0].locationData.cityZip}`}
                      </a>
                    </div>
                    <div className="contact-sidebar-block">
                      <h7>{language === "es" ? "Teléfono" : "Phone"}</h7>
                      <a
                        href={`tel:+1${telephoneStrip(
                          locations[0].locationData.phone
                        )}`}>
                        {locations[0].locationData.phoneAlias}
                      </a>
                    </div>

                    <div className="contact-sidebar-block">
                      <GeneralHours
                        language={language}
                        contact
                        locationHours={locationHours}
                      />
                    </div>

                    <div className="contact-socials">
                      {socials.map(social => (
                        <React.Fragment key={social.link}>
                          <SocialIconLink
                            platform={social.platform}
                            link={social.link}
                          />
                        </React.Fragment>
                      ))}
                    </div>

                    <div className="contact-sidebar-block mt-3">
                      {post.sidebar.hasThisSection && (
                        <div className="sidebar-buttons">
                          <h7>{post.sidebar.heading}</h7>
                          <ButtonGroupMap buttons={post.sidebar.buttons} />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(
                    post.contactBody.heading + post.contactBody.blurb
                  )
                )}
              />

              <div className="contact-form-section">
                {locations.length === 1 ? (
                  <>
                    <div className="inline-block">
                      <span className="required-fields">
                        <span className="required-asterisk">*</span> Required
                        Fields
                      </span>
                    </div>
                    <Seamless id="d542f387003450090e266b79990bf284" />
                  </>
                ) : (
                  <div>
                    <div className="locations-grid">
                      <LocationBlocks
                        language={language}
                        locations={locations}
                        setActiveForm={setActiveForm}
                        handleFormChange={handleFormChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="column is-2" />
            <div className="column is-narrow contact-info is-hidden-mobile">
              {locations.length > 1 && (
                <>
                  <div className="contact-sidebar-block">
                    <AllSocialReviews language={language} isColumn />
                  </div>
                </>
              )}
              {locations.length === 1 && (
                <>
                  <div className="contact-sidebar-block">
                    <h7>{language === "es" ? "Dirección" : "Address"}</h7>
                    <a
                      href={`${locations[0].locationData.googlePlaceLink}`}
                      target="_blank"
                      rel="noreferrer">
                      {`${locations[0].locationData.streetNumber} ${locations[0].locationData.streetName}`}
                      <br />
                      {`${locations[0].locationData.cityName}, ${locations[0].locationData.stateAbbr} ${locations[0].locationData.cityZip}`}
                    </a>
                  </div>
                  <div className="contact-sidebar-block">
                    <h7>{language === "es" ? "Teléfono" : "Phone"}</h7>
                    <a
                      href={`tel:+1${telephoneStrip(
                        locations[0].locationData.phone
                      )}`}>
                      {locations[0].locationData.phoneAlias}
                    </a>
                  </div>
                  <div className="contact-sidebar-block">
                    <GeneralHours
                      language={language}
                      contact
                      locationHours={locationHours}
                    />
                  </div>
                  <div className="contact-socials">
                    {socials.map(social => (
                      <React.Fragment key={social.link}>
                        <SocialIconLink
                          platform={social.platform}
                          link={social.link}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="contact-reviews">
                    <AllRatingsReviews isColumn />
                  </div>
                </>
              )}
            </div>
            <div className="column is-5 is-hidden-tablet" />
          </div>

          {language === "es" && (
            <div className="columns">
              <div className="column is-4" />
              <div className="column">
                <div className="contact-reviews contact-reviews-es big">
                  {/* <SocialReviews inlineStars iconless google facebook googleHeading language={language} /> */}
                  {/* <Ratings language={language} google={google} facebook={facebook} eastGoogle={east} coastalGoogle={coastal} /> */}
                </div>
              </div>
              <div className="column is-4" />
            </div>
          )}
        </div>

        {language !== "es" && (
          <LocationForms
            activeForm={activeForm}
            setActiveForm={setActiveForm}
            pageUpdated={pageState}
          />
        )}
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query contactUsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        contactBody {
          heading
          blurb
        }
        sidebar {
          hasThisSection
          heading
          buttons {
            button {
              href
              buttonText
              appearance
              destination
            }
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }

    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }
    allLocationsJson(sort: { fields: locationData___order }) {
      nodes {
        locationData {
          ...locationDataFields
          phoneAlias
        }
        pageData {
          ...locationPageURL
          layout_3 {
            formSection {
              hasForm
              seamlessShortcode
            }
          }
        }
      }
    }
  }
`

export default ContactUsPage
